import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useVoucherStore } from '../../../stores/useVoucherStore';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';

export enum VoucherClass {
  influencer = 'influencer',
  strategic_partner = 'strategic_partner',
  closed_user_group = 'closed_user_group',
}

export enum VoucherType {
  RELATIVE = 'relative-value',
  ABSOLUTE = 'absolute-value',
}

export type ReferralVoucherData = {
  amount: number;
  type: string;
  code: string;
  is_valid: boolean;
  referrer: string;
  value_abs: number;
  value_rel: number;
  image: { url: string };
  class: VoucherClass;
};

export const isValidRelativePartnerVoucher = (
  voucherData: ReferralVoucherData
) => {
  return voucherData?.is_valid && voucherData?.value_rel;
};

export type PartialCartInfo = Partial<{
  voucherClass: VoucherClass;
  voucherType: VoucherType;
  voucherAmount: number;
  originalAmount: number;
}>;

export const getPartnerDiscountFromDeal = (
  cartInfo: PartialCartInfo
): number => {
  if (cartInfo?.voucherType !== VoucherType.RELATIVE) {
    return 0;
  }
  if (!cartInfo?.voucherAmount || !cartInfo?.originalAmount) {
    return 0;
  }

  return cartInfo.voucherAmount / cartInfo.originalAmount;
};

export const usePartnerDiscount = ({ closedUserGroupId, cartInfo }) => {
  const voucherData = useVoucherStore((state) => state.referralVoucherData);
  const [partnerDiscount, setPartnerDiscount] = useState(0);
  const partnerDiscountFromDeal = getPartnerDiscountFromDeal(cartInfo);
  const pathname = usePathname();
  const { router } = useDiscoveryConfig();
  const routerPath = pathname || router?.asPath || '';
  useEffect(() => {
    if (closedUserGroupId) {
      return;
    }
    if (isValidRelativePartnerVoucher(voucherData)) {
      setPartnerDiscount(voucherData.value_rel);
    }
  }, [voucherData, routerPath, closedUserGroupId]);

  return partnerDiscountFromDeal || partnerDiscount;
};
