// putting it in ua-vehicle as one of the custom hooks in ua-vehicle depends on
// data in it and putting it in platform modules create circular dependency
import { REFERRAL_VOUCHER_COOKIE_EXPIRATION } from '@finn/ua-constants';
import {
  clearClientCookie,
  CookieKeys,
  getClientJSONCookie,
  setClientCookie,
} from '@finn/ui-utils';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ReferralVoucherData } from '../modules/product-pricing/hooks/usePartnerDiscount';

type VoucherState = {
  referralVoucherData?: ReferralVoucherData;
  setReferralVoucherData: (data: ReferralVoucherData) => void;
  initFromBrowserCookie: () => void;
  clearReferralVoucher: () => void;
};

const getVoucherDataFromCookie = () => {
  const voucherCookieData = getClientJSONCookie(
    CookieKeys.REFERRAL_VOUCHER_CODE
  );

  return voucherCookieData;
};

export const useVoucherStore = create<VoucherState>()(
  devtools(
    immer((set) => ({
      setReferralVoucherData: (data) => {
        if (!data?.is_valid) return;
        setClientCookie(
          CookieKeys.REFERRAL_VOUCHER_CODE,
          JSON.stringify(data),
          REFERRAL_VOUCHER_COOKIE_EXPIRATION
        );
        set((state) => {
          state.referralVoucherData = data;
        });
      },
      // initFromBrowserCookie should be called only on client side (e.g. in useEffect)
      initFromBrowserCookie: () => {
        set((state) => {
          const data = getVoucherDataFromCookie();
          if (!data?.is_valid) return;
          state.referralVoucherData = data;
        });
      },
      clearReferralVoucher: () => {
        clearClientCookie(CookieKeys.REFERRAL_VOUCHER_CODE);
        set((state) => {
          state.referralVoucherData = undefined;
        });
      },
    })),
    { name: 'VoucherStore' }
  )
);
